import htmx from 'htmx.org';

// Certifique-se de que htmx está disponível globalmente
window.htmx = htmx;

function AppState() {
  this.actions = {};
  this.subscriptions = [];
  this.history = [];
}

/**
 * Subscribes an element to a specific action.
 * @param {Object} element - The DOM element.
 * @param {string} action - The action to subscribe to.
 * @param {Function} callback - The callback to execute when the action is dispatched.
 */
AppState.prototype.subscribe = function (element, action, callback) {
  this.subscriptions[action] = this.subscriptions[action] || [];
  this.subscriptions[action].push(function (data) {
    callback.apply(element, data);
  });
};

/**
 * Dispatches an action and notifies subscribers.
 * @param {string} action - The action to dispatch.
 * @param {Array} [data=[]] - The data to pass to the action and subscribers.
 */
AppState.prototype.dispatch = function (action, data) {
  data = data || [];

  // Store history of actions
  this.history.push([action, data]);

  // Call action reducers
  if (typeof this[action] === 'function') {
    this[action].apply(this, data);
  }

  // Add the action and state as final arguments
  data.push(action);
  data.push(this);

  // Call subscribers
  this.subscriptions[action] = this.subscriptions[action] || [];
  this.subscriptions[action].forEach(function (subscription) {
    subscription(data);
  });
};

let currentStepIndex = 0;
const totalFormSteps = 7;
let formStateData = JSON.parse(localStorage.getItem('formData')) || {};
const appState = new AppState();

document.addEventListener('DOMContentLoaded', function () {
  clearMainForm();
  showStep(currentStepIndex);
});

function showStep(n) {
  const stepsContainer = document.getElementById('stepsContainer');
  stepsContainer.innerHTML = '';
  htmx
    .ajax('GET', `boeing/steps/step${n + 1}.html`, {
      target: '#stepsContainer',
    })
    .then(() => {
      const newStepElement = document.querySelector('.step');
      if (newStepElement) {
        newStepElement.classList.add('active');
        populateFormData();
      }
    });

  document.getElementById('prevBtn').style.display =
    n === 0 ? 'none' : 'inline';
  if (n === totalFormSteps - 1) {
    document.getElementById('nextBtn').innerHTML = 'Submit';
    document.getElementById('nextBtn').onclick = function () {
      if (validateStep()) {
        saveFormData();
        showModal();
      }
    };
  } else {
    document.getElementById('nextBtn').innerHTML = 'Next';
    document.getElementById('nextBtn').onclick = function () {
      nextPrev(1);
    };
  }
}

function validateStep() {
  const currentStepElement = document.querySelector(
    '#stepsContainer .step.active'
  );
  if (!currentStepElement) return false;

  // Validation for question 4
  if (currentStepIndex === 2) {
    const question4Checkbox = document.querySelector('input[name="question4"]');
    const hooksTable = document
      .getElementById('hooksTable')
      .getElementsByTagName('tbody')[0];

    if (!question4Checkbox.checked && hooksTable.rows.length === 0) {
      alert(
        'Please add at least one item to the table or select N/A for Question 4.'
      );
      return false;
    }
  }

  const inputs = currentStepElement.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    if (
      inputs[i].type !== 'radio' &&
      inputs[i].type !== 'checkbox' &&
      inputs[i].value === ''
    ) {
      alert('Please fill all the fields');
      return false;
    }
    if (inputs[i].type === 'radio') {
      const name = inputs[i].name;
      const radios = document.getElementsByName(name);
      const checked = Array.from(radios).some((radio) => radio.checked);
      if (!checked) {
        alert('Please answer all the questions');
        return false;
      }
    }
  }
  saveFormData();
  return true;
}

export function nextPrev(n) {
  if (n === 1 && !validateStep()) return false;
  currentStepIndex = currentStepIndex + n;
  if (currentStepIndex >= totalFormSteps) {
    submitForm();
    return false;
  }
  if (currentStepIndex < 0) return false;
  showStep(currentStepIndex);
}

export function clearMainForm() {
  const form = document.getElementById('inspectionForm');
  form.reset();
  currentStepIndex = 0;
  formStateData = {};
  localStorage.removeItem('formData');
  showStep(currentStepIndex);
}

function saveFormData() {
  const inputs = document.querySelectorAll(
    '#stepsContainer .step.active input'
  );
  inputs.forEach((input) => {
    if (input.type === 'radio' || input.type === 'checkbox') {
      if (input.checked) {
        formStateData[input.name] = input.value;
      }
    } else {
      formStateData[input.name] = input.value;
    }
  });

  // Save hooks data
  if (currentStepIndex === 2) {
    const hooksData = [];
    const rows = document.querySelectorAll('#hooksTable tbody tr');
    rows.forEach((row) => {
      const hookData = {};
      const rowInputs = row.querySelectorAll('input');
      rowInputs.forEach((input) => {
        if (input.type === 'radio' && input.checked) {
          hookData[input.name.split('_')[1]] = input.value;
        } else if (input.type !== 'radio') {
          hookData[input.name.split('_')[1]] = input.value;
        }
      });
      hooksData.push(hookData);
    });
    formStateData['hooksData'] = hooksData;
  }

  localStorage.setItem('formData', JSON.stringify(formStateData));
}

function populateFormData() {
  for (const name in formStateData) {
    const input = document.querySelector(
      `#stepsContainer .step.active input[name="${name}"]`
    );
    if (input) {
      if (input.type === 'radio' || input.type === 'checkbox') {
        const radios = document.querySelectorAll(
          `#stepsContainer .step.active input[name="${name}"]`
        );
        radios.forEach((radio) => {
          if (radio.value === formStateData[name]) {
            radio.checked = true;
          }
        });
      } else {
        input.value = formStateData[name];
      }
    }
  }

  // Populate hooks data
  if (currentStepIndex === 2 && formStateData['hooksData']) {
    const hooksTable = document
      .getElementById('hooksTable')
      .getElementsByTagName('tbody')[0];
    hooksTable.innerHTML = '';
    formStateData['hooksData'].forEach((hookData) => {
      addRow(
        hookData.sn || '',
        hookData.cracks || '',
        hookData.throat || '',
        hookData.twisted || ''
      );
    });
    checkRowLimit();
  }
}

function showModal() {
  saveFormData();
  htmx
    .ajax('GET', 'boeing/modal/modal.html', { target: '#modals-here' })
    .then(() => {
      const isDarkTheme = document.body.classList.contains('dark-theme');
      if (isDarkTheme) {
        document.querySelector('.modal-overlay').classList.add('dark-theme');
      }
      applyModalStyles();
    });
}

function applyModalStyles() {
  const pinInputs = document.querySelectorAll('.pin-input input');
  pinInputs.forEach((input) => {
    input.style.width = '40px';
    input.style.height = '40px';
    input.style.textAlign = 'center';
    input.style.fontSize = '24px';
  });
}

let websocket;
const locationObj = window.location;
let websocketUri = locationObj.protocol === 'https:' ? 'wss:' : 'ws:';
websocketUri +=
  '//' +
  locationObj.host +
  locationObj.pathname.replace('index.html', 'ws/boeing777');

// websocketUri = 'ws://localhost:1880/ws/boeing777';

function wsConnect() {
  websocket = new WebSocket(websocketUri);

  websocket.onmessage = function (msg) {
    const data = JSON.parse(msg.data);
    const { topic, action } = data;

    const formContainer = document.getElementById('formContainer');

    if (formContainer) {
      if (topic === 'main') {
        formContainer.style.display = action.show === 1 ? 'block' : 'none';
      } else if (topic === 'modal') {
        if (action.show === 1) {
          showModal();
        } else if (action.show === 0) {
          closeModal();
        }
      } else if (topic === 'acknowledgment') {
        if (action.show === 1) {
          showAcknowledgment();
        } else if (action.show === 0) {
          closeAcknowledgment();
        }
      } else if (topic === 'pin') {
        if (action.show === 1) {
          openPin();
        } else if (action.show === 0) {
          closeModal();
        }
      } else if (topic === 'countdown') {
        const reminderTime = document.getElementById('reminderTime');
        reminderTime.textContent =
          'Crane Inspection Reminder Time: ' + action.remainder_time;
      }
    }
  };

  websocket.onopen = function () {
    const status = document.getElementById('status');
    if (status) {
      status.innerHTML = 'connected';
    }
  };

  websocket.onclose = function () {
    const status = document.getElementById('status');
    if (status) {
      status.innerHTML = 'not connected';
    }
    setTimeout(wsConnect, 3000);
  };
}

document.addEventListener('DOMContentLoaded', function () {
  wsConnect();
});

export function closeModal() {
  const modalsHere = document.getElementById('modals-here');
  modalsHere.innerHTML = '';
}

function closeAcknowledgment() {
  const modalsHere = document.getElementById('modals-ack');
  modalsHere.innerHTML = '';
}

export function handleQuestionChange(question) {
  const value = document.querySelector(
    `input[name="${question}"]:checked`
  ).value;

  if (question === 'question5') {
    if (value === 'N/A') {
      disableQuestionAndSubs('question5');
      setRadioValue('question6', 'N/A');
      disableQuestionAndSubs('question6');
      setRadioValue('question7', 'N/A');
      disableQuestionAndSubs('question7');
      nextPrev(3);
    } else {
      enableQuestionAndSubs('question5');
    }
  } else if (question === 'question6') {
    if (value === 'N/A') {
      disableQuestionAndSubs('question6');
      setRadioValue('question7', 'N/A');
      disableQuestionAndSubs('question7');
      nextPrev(2);
    } else {
      enableQuestionAndSubs('question6');
    }
  } else if (question === 'question7') {
    if (value === 'N/A') {
      disableQuestionAndSubs('question7');
      nextPrev(1);
    } else {
      enableQuestionAndSubs('question7');
    }
  }
}

function disableQuestionAndSubs(questionGroup) {
  const subQuestions = document.querySelectorAll(
    `[name^="${questionGroup}a"], [name^="${questionGroup}b"], [name^="${questionGroup}c"]`
  );
  subQuestions.forEach((subQuestion) => {
    subQuestion.disabled = true;
    subQuestion.checked = true;
  });
}

function enableQuestionAndSubs(questionGroup) {
  const mainQuestion = document.querySelector(`input[name="${questionGroup}"]`);
  if (mainQuestion && mainQuestion.value !== 'N/A') {
    const subQuestions = document.querySelectorAll(
      `[name^="${questionGroup}a"], [name^="${questionGroup}b"], [name^="${questionGroup}c"]`
    );
    subQuestions.forEach((subQuestion) => {
      subQuestion.disabled = false;
    });
  }
}

function setRadioValue(questionGroup, value) {
  const radio = document.querySelector(
    `input[name="${questionGroup}"][value="${value}"]`
  );
  if (radio) {
    radio.checked = true;
  }
}

export function addRow(sn = '', cracks = '', throat = '', twisted = '') {
  const table = document
    .getElementById('hooksTable')
    .getElementsByTagName('tbody')[0];
  if (table.rows.length >= 5) {
    alert(
      'You can only add up to 5 rows. Please remove a row to add a new one.'
    );
    return;
  }

  const newRow = table.insertRow();

  const cells = ['sn', 'cracks', 'throat', 'twisted'];
  cells.forEach((cell, index) => {
    const newCell = newRow.insertCell(index);
    newCell.setAttribute(
      'data-label',
      document.querySelector(`.hooks-table th:nth-child(${index + 1})`)
        .textContent
    );

    if (cell === 'sn') {
      const input = document.createElement('input');
      input.type = 'text';
      input.name = `hook${table.rows.length}_${cell}`;
      input.classList.add('bx--text-input');
      input.value = sn;
      input.addEventListener('change', updateHooksData);
      newCell.appendChild(input);
    } else {
      ['Y', 'N'].forEach((value) => {
        const label = document.createElement('label');
        const radio = document.createElement('input');
        radio.type = 'radio';
        radio.name = `hook${table.rows.length}_${cell}`;
        radio.value = value;
        radio.checked =
          value ===
          (cell === 'cracks' ? cracks : cell === 'throat' ? throat : twisted);
        radio.addEventListener('change', updateHooksData);
        label.appendChild(radio);
        label.appendChild(document.createTextNode(value));
        newCell.appendChild(label);
      });
    }
  });

  const deleteCell = newRow.insertCell(cells.length);
  deleteCell.setAttribute('data-label', 'Action');
  const deleteButton = document.createElement('button');
  deleteButton.type = 'button';
  deleteButton.className = 'remove-button bx--btn bx--btn--secondary';
  deleteButton.textContent = 'Delete';
  deleteButton.onclick = function () {
    removeRow(deleteButton);
  };
  deleteCell.appendChild(deleteButton);

  checkRowLimit();
  updateHooksData();
}

function updateHooksData() {
  const hooksData = [];
  const rows = document.querySelectorAll('#hooksTable tbody tr');
  rows.forEach((row) => {
    const hookData = {};
    const inputs = row.querySelectorAll('input');
    inputs.forEach((input) => {
      if (input.type === 'radio' && input.checked) {
        hookData[input.name.split('_')[1]] = input.value;
      } else if (input.type !== 'radio') {
        hookData[input.name.split('_')[1]] = input.value;
      }
    });
    hooksData.push(hookData);
  });
  formStateData['hooksData'] = hooksData;
  localStorage.setItem('formData', JSON.stringify(formStateData));
}

export function handleQuestion4Change() {
  const question4Checkbox = document.querySelector('input[name="question4"]');
  const hooksTable = document
    .getElementById('hooksTable')
    .getElementsByTagName('tbody')[0];
  const addButton = document.getElementById('addRowButton');

  if (question4Checkbox.checked) {
    hooksTable.innerHTML = '';
    formStateData['hooksData'] = [];
    addButton.disabled = true;
  } else {
    addButton.disabled = false;
    if (hooksTable.rows.length === 0) {
      addRow();
    }
  }
  updateHooksData();
}

function removeRow(button) {
  const row = button.parentNode.parentNode;
  row.parentNode.removeChild(row);
  checkRowLimit();
  updateHooksData();
}

function checkRowLimit() {
  const table = document
    .getElementById('hooksTable')
    .getElementsByTagName('tbody')[0];
  const addButton = document.getElementById('addRowButton');
  addButton.disabled = table.rows.length >= 5;
}
