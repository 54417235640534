// Importações de bibliotecas e estilos
import $ from 'jquery';
import htmx from 'htmx.org';

import '@assets/css/reset.min.css';
import 'carbon-components/css/carbon-components.min.css';
import '@assets/css/main.css';

// Importações de módulos JS
import {
  handleQuestionChange,
  handleQuestion4Change,
  addRow,
  nextPrev,
  clearMainForm,
  closeModal,
} from '@assets/js/main';
import {
  openAdminSettings,
  openPin,
  toggleTheme,
  openRegistrationForm,
} from '@assets/js/globals';
import {
  updatePagination,
  loadTableData,
  nextPage,
  previousPage,
  selectAll,
  deleteRow,
  initializeCarbonComponents,
  updateSelectedCount,
  activateRow,
  deleteSelected,
  cancelSelection,
} from '@assets/js/admin-settings';

// Configuração de variáveis globais
window.$ = $;
window.jQuery = $;
window.htmx = htmx;
window.addRow = addRow;
window.nextPrev = nextPrev;
window.closeModal = closeModal;
window.clearMainForm = clearMainForm;
window.handleQuestionChange = handleQuestionChange;
window.handleQuestion4Change = handleQuestion4Change;
window.openPin = openPin;
window.toggleTheme = toggleTheme;
window.updatePagination = updatePagination;
window.loadTableData = loadTableData;
window.nextPage = nextPage;
window.previousPage = previousPage;
window.selectAll = selectAll;
window.initializeCarbonComponents = initializeCarbonComponents;
window.deleteRow = deleteRow;
window.activateRow = activateRow;
window.updateSelectedCount = updateSelectedCount;
window.deleteSelected = deleteSelected;
window.cancelSelection = cancelSelection;
window.openAdminSettings = openAdminSettings;
window.openRegistrationForm = openRegistrationForm;

// Inicialização do documento
document.addEventListener('DOMContentLoaded', () => {
  console.log('Document is ready');
  // Seu código jQuery aqui
});
