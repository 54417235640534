import htmx from 'htmx.org';
import { Modal } from 'carbon-components';

export function toggleTheme() {
  const isDarkTheme = document.body.classList.toggle('dark-theme');
  const icon = document.querySelector('.theme-toggle svg');
  icon.innerHTML = isDarkTheme
    ? '<path d="M15 2H17V7H15z"></path><path d="M21.668 6.854H26.625999999999998V8.854H21.668z" transform="rotate(-45 24.147 7.853)"></path><path d="M25 15H30V17H25z"></path><path d="M23.147 21.668H25.147V26.625999999999998H23.147z" transform="rotate(-45 24.147 24.146)"></path><path d="M15 25H17V30H15z"></path><path d="M5.375 23.147H10.333V25.147H5.375z" transform="rotate(-45 7.853 24.146)"></path><path d="M2 15H7V17H2z"></path><path d="M6.854 5.375H8.854V10.333H6.854z" transform="rotate(-45 7.854 7.853)"></path><path d="M16,10a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z"></path><title>Light filled</title>'
    : '<path d="M15 2H17V7H15z"></path><path d="M21.668 6.854H26.625999999999998V8.854H21.668z" transform="rotate(-45 24.147 7.853)"></path><path d="M25 15H30V17H25z"></path><path d="M23.147 21.668H25.147V26.625999999999998H23.147z" transform="rotate(-45 24.147 24.146)"></path><path d="M15 25H17V30H15z"></path><path d="M5.375 23.147H10.333V25.147H5.375z" transform="rotate(-45 7.853 24.146)"></path><path d="M2 15H7V17H2z"></path><path d="M6.854 5.375H8.854V10.333H6.854z" transform="rotate(-45 7.854 7.853)"></path><path d="M16,12a4,4,0,1,1-4,4,4.0045,4.0045,0,0,1,4-4m0-2a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z"></path><title>Light</title>';
  document.documentElement.style.setProperty(
    '--background-color',
    isDarkTheme ? '#262626' : '#f4f4f4'
  );
  document.documentElement.style.setProperty(
    '--text-color',
    isDarkTheme ? '#f4f4f4' : '#000'
  );
  document.documentElement.style.setProperty(
    '--close-button-color',
    isDarkTheme ? '#f4f4f4' : '#000'
  );
  document.documentElement.style.setProperty(
    '--table-bg',
    isDarkTheme ? '#333' : '#fff'
  );
  document.documentElement.style.setProperty(
    '--table-text-color',
    isDarkTheme ? '#f4f4f4' : '#000'
  );
  document.documentElement.style.setProperty(
    '--table-border-color',
    isDarkTheme ? '#444' : '#e0e0e0'
  );
}

function openModalWithContent(url, targetSelector, callback) {
  htmx
    .ajax('GET', url, { target: targetSelector })
    .then(() => {
      const modalElement = document.querySelector(targetSelector + ' .modal');
      if (modalElement) {
        const modal = Modal.create(modalElement);
        modal.show();
        if (callback) {
          callback();
        }
      } else {
        console.error('Modal element not found');
      }
    })
    .catch((error) => {
      console.error('Error loading content:', error);
    });
}

export function openPin() {
  const style = document.createElement('style');
  style.innerHTML = `
      .modal {
        background: var(--modal-bg);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 10px var(--container-shadow);
        width: 400px;
        max-width: 90%;
        color: var(--modal-text);
        position: relative;
        z-index: 1001;
      }
  `;
  document.head.appendChild(style);
  openModalWithContent('boeing/modal/login.html', '#modals-here', () => {
    const isDarkTheme = document.body.classList.contains('dark-theme');
    if (isDarkTheme) {
      document.querySelector('.modal').classList.add('dark-theme');
    }
  });
}

export function openRegistrationForm() {
  const style = document.createElement('style');
  style.innerHTML = `
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
      }
      
      .modal {
        background: var(--modal-bg);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 10px var(--container-shadow);
        width: 400px;
        max-width: 90%;
        color: var(--modal-text);
        position: relative;
        z-index: 1001;
      }
      
      .modal .logo {
        display: block;
        margin: 0 auto 10px;
      }
      
      .modal h2 {
        text-align: center;
      }
    
  `;
  document.head.appendChild(style);
  openModalWithContent('boeing/modal/registration.html', '#modals-here', () => {
    const isDarkTheme = document.body.classList.contains('dark-theme');
    if (isDarkTheme) {
      document.querySelector('.modal').classList.add('dark-theme');
    }
  });
}

export function openAdminSettings() {
  const style = document.createElement('style');
  style.innerHTML = `
    .modal {
      background: var(--modal-bg);
      padding: 30px;
      border-radius: 8px;
      box-shadow: 0 2px 10px var(--modal-shadow);
      width: 100%;
      max-width: 1024px;
      color: var(--text-color);
      position: relative;
      z-index: 1001;
      overflow: auto;
    }
  `;
  document.head.appendChild(style);
  openModalWithContent(
    'boeing/modal/admin-settings.html',
    '#modals-here',
    () => {
      const isDarkTheme = document.body.classList.contains('dark-theme');
      if (isDarkTheme) {
        document.querySelector('.modal').classList.add('dark-theme');
      }
    }
  );
}

function applyModalStyles() {
  const pinInputs = document.querySelectorAll('.pin-input input');
  pinInputs.forEach((input) => {
    input.style.width = '40px';
    input.style.height = '40px';
    input.style.textAlign = 'center';
    input.style.fontSize = '24px';
  });
}
