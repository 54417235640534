import { Modal, OverflowMenu } from 'carbon-components';
import $ from 'jquery';

let currentPage = 1;
let itemsPerPage = 10;
let totalItems = 0;
let totalPages = 0;
let tableData = [];

export function selectAll(selectAllCheckbox) {
  const checkboxes = document.querySelectorAll('#table-body .bx--checkbox');
  checkboxes.forEach((checkbox) => {
    checkbox.checked = selectAllCheckbox.checked;
  });
  updateSelectedCount();
}

export function cancelSelection() {
  // Desmarcar todos os checkboxes no corpo da tabela
  const checkboxes = document.querySelectorAll('#table-body .bx--checkbox');
  checkboxes.forEach((checkbox) => {
    checkbox.checked = false;
  });

  // Desmarcar o checkbox no título da tabela
  const selectAllCheckbox = document.querySelector(
    '.bx--table-column-checkbox .bx--checkbox'
  );
  if (selectAllCheckbox) {
    selectAllCheckbox.checked = false;
  }

  // Atualizar a contagem de itens selecionados
  updateSelectedCount();
}

export function updateSelectedCount() {
  const selectedCount = document.querySelectorAll(
    '#table-body .bx--checkbox:checked'
  ).length;
  document.querySelector('[data-items-selected]').innerText = selectedCount;
  const batchActions = document.querySelector('.bx--batch-actions');
  if (selectedCount > 0) {
    batchActions.classList.add('bx--batch-actions--active');
  } else {
    batchActions.classList.remove('bx--batch-actions--active');
  }
}

export function deleteSelected() {
  const selectedCheckboxes = document.querySelectorAll(
    '#table-body .bx--checkbox:checked'
  );
  const ids = Array.from(selectedCheckboxes).map((checkbox) => checkbox.value);

  if (ids.length) {
    $.ajax({
      url: `delete?rowIds=${ids.join(',')}`,
      method: 'DELETE',
      success: function () {
        loadTableData();
        initializeCarbonComponents(); // Reinitialize Carbon Components
      },
      error: function (error) {
        console.error('Error deleting rows', error);
      },
    });
  }
}

export function deleteRow(rowId) {
  $.ajax({
    url: `delete?rowIds=${rowId}`,
    method: 'DELETE',
    success: function () {
      loadTableData();
    },
    error: function (error) {
      console.error('Error deleting row', error);
    },
  });
}

export function activateRow(rowId) {
  $.ajax({
    url: `active?rowIds=${rowId}`,
    method: 'PUT',
    success: function () {
      loadTableData();
    },
    error: function (error) {
      console.error('Error activating row', error);
    },
  });
}

export function updatePagination() {
  const itemsPerPageSelect = document.querySelector('[data-items-per-page]');
  const pageSelect = document.querySelector('[data-page-number-input]');
  itemsPerPage = parseInt(itemsPerPageSelect.value);
  currentPage = parseInt(pageSelect.value);
  totalItems = tableData.length;
  totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  document.querySelector(
    '[data-displayed-item-range]'
  ).innerText = `${startItem}-${endItem}`;
  document.querySelector('[data-total-items]').innerText = totalItems;

  loadTableData(currentPage, itemsPerPage);
}

export function previousPage() {
  if (currentPage > 1) {
    currentPage--;
    document.querySelector('[data-page-number-input]').value = currentPage;
    updatePagination();
  }
}

export function nextPage() {
  if (currentPage < totalPages) {
    currentPage++;
    document.querySelector('[data-page-number-input]').value = currentPage;
    updatePagination();
  }
}

export function loadTableData(page = 1, itemsPerPage = 10) {
  $.ajax({
    url: `dados?pageSize=${itemsPerPage}&page=${page}`,
    method: 'GET',
    success: function (response) {
      if (response && response.rows) {
        tableData = response.rows.map((row) => ({
          'Row ID': row.id,
          'User ID':
            row.cells.find((cell) => cell.columnId === response.columns[0].id)
              ?.displayValue || '',
          'User Type':
            row.cells.find((cell) => cell.columnId === response.columns[1].id)
              ?.displayValue || '',
          'RFID Card Number':
            row.cells.find((cell) => cell.columnId === response.columns[2].id)
              ?.displayValue || '',
          'Training Issue Date':
            row.cells.find((cell) => cell.columnId === response.columns[3].id)
              ?.value || 'N/A',
          'Training Expiry Date':
            row.cells.find((cell) => cell.columnId === response.columns[4].id)
              ?.value || 'N/A',
          Active:
            row.cells.find((cell) => cell.columnId === response.columns[5].id)
              ?.displayValue === 'true',
          Notes:
            row.cells.find((cell) => cell.columnId === response.columns[6].id)
              ?.displayValue || '',
        }));
        totalItems = response.totalRowCount;
        totalPages = Math.ceil(totalItems / itemsPerPage);
        renderTableData();
        updatePaginationUI();
      } else {
        console.error('Invalid data format');
      }
    },
    error: function (error) {
      console.error('Error loading data', error);
    },
  });
}

function renderTableData() {
  const tableBody = document.getElementById('table-body');
  if (!tableBody) return; // Ensure tableBody is not null
  tableBody.innerHTML = '';
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  for (let i = startIndex; i < endIndex; i++) {
    const row = tableData[i];
    const tableRow = document.createElement('tr');
    tableRow.innerHTML = `
      <td class="bx--table-column-checkbox">
        <input data-event="select" id="bx--checkbox-${i}" class="bx--checkbox" type="checkbox" value="${
      row['Row ID']
    }" onclick="updateSelectedCount()">
        <label for="bx--checkbox-${i}" class="bx--checkbox-label" aria-label="Label name"></label>
      </td>
      <td>${row['User ID']}</td>
      <td>${row['User Type']}</td>
      <td>${row['RFID Card Number']}</td>
      <td>${row['Training Issue Date']}</td>
      <td>${row['Training Expiry Date']}</td>
      <td>${row['Active'] ? 'Yes' : 'No'}</td>
      <td>${row['Notes']}</td>
      <td class="bx--table-column-menu">
        <div data-overflow-menu role="menu" tabindex="0" aria-label="Overflow menu description" class="bx--overflow-menu">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;" xmlns="http://www.w3.org/2000/svg" class="bx--overflow-menu__icon" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
            <circle cx="8" cy="3" r="1"></circle>
            <circle cx="8" cy="8" r="1"></circle>
            <circle cx="8" cy="13" r="1"></circle>
          </svg>
          <ul class="bx--overflow-menu-options bx--overflow-menu--flip">
            <li class="bx--overflow-menu-options__option">
              <button class="bx--overflow-menu-options__btn" onclick="deleteRow('${
                row['Row ID']
              }')">
                <div class="bx--overflow-menu-options__option-content">Delete</div>
              </button>
            </li>
            <li class="bx--overflow-menu-options__option">
              <button class="bx--overflow-menu-options__btn" onclick="activateRow('${
                row['Row ID']
              }')">
                <div class="bx--overflow-menu-options__option-content">Activate</div>
              </button>
            </li>
          </ul>
        </div>
      </td>
    `;
    tableBody.appendChild(tableRow);
  }
  initializeCarbonComponents(); // Initialize Carbon Components
}

function updatePaginationUI() {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const displayedItemRangeElement = document.querySelector(
    '[data-displayed-item-range]'
  );
  const totalItemsElement = document.querySelector('[data-total-items]');
  const pageNumberInputElement = document.querySelector(
    '[data-page-number-input]'
  );

  if (displayedItemRangeElement) {
    displayedItemRangeElement.innerText = `${startItem}-${endItem}`;
  }

  if (totalItemsElement) {
    totalItemsElement.innerText = totalItems;
  }

  if (pageNumberInputElement) {
    pageNumberInputElement.value = currentPage;
  }
}

export function initializeCarbonComponents() {
  Modal.init();
  OverflowMenu.init();
}
